:root {
  --eiddesk-neutral: #ffffff;
  --eiddesk-primary-color: #122f5a;
  --eiddesk-primary-variant-color: #122f5a;
  --eiddesk-on-primary-color: #ffffff;
  --eiddesk-on-primary-variant-color: #ffffff;
  --eiddesk-text-color: rgba(0, 0, 0, 0.85);
  --eiddesk-bg-color: #f0f2f5;
  --eiddesk-component-bg-color: #ffffff;
  --eiddesk-org-highlight: orange;
  --eiddesk-org-admin-highlight: red;
  --eiddesk-badge-color: #f0f0f0;
  --eiddesk-badge-text-color: rgba(0, 0, 0, 0.45);
}
